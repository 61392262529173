import axios from "axios";
import { BASE_API } from "../../Services/FirebaseService/FirebaseService";
type BuyRaffleTicketsPayload = {
  walletAddress: string;
  trans: any;
  numTickets: number;
};

export const buyRaffleTickets = (
  raffleId: string,
  payload: BuyRaffleTicketsPayload
) =>
  new Promise<any>((resolve: any) => {
    const data = {
      data: { ...payload, raffleId },
    };
    const config: any = {
      method: "post",
      // url: `https://us-central1-nft-anybodies.cloudfunctions.net/MINT_RAFFLE_purchasePresaleRequest`,
      url: `${BASE_API}/purchasePresaleRequest`,
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        resolve(undefined);
      });
  });
