import { createContext, useContext, useState } from "react";
import { CarouselItem } from "../CarouselItem/CarouselItem";
import "./PreviewOverlay.scss";

const PreviewOverlayContext = createContext<any>({});

export const usePreviewOverlay = () => useContext(PreviewOverlayContext);

export const PreviewOverlay = ({ children }: any) => {
  const [preview, setPreview] = useState<any>();

  const openPreview = (previewData: any) => {
    setPreview(previewData);
  };

  const closePreview = () => {
    setPreview(null);
  };

  const value = {
    openPreview,
  };
  return (
    <PreviewOverlayContext.Provider value={value}>
      {children}
      <div
        onClick={closePreview}
        className={
          !!preview
            ? `preview-overlay-main-container-show`
            : "preview-overlay-main-container"
        }
      >
       { !!preview && <>
          <CarouselItem />
        </>}
      </div>
    </PreviewOverlayContext.Provider>
  );
};
