import "./App.scss";

import { PreviewOverlay } from "./Components/PreviewOverlay/PreviewOverlay";
import { MainLayout } from "./MainLayout/MainLayout";
import { BrowserRouter } from "react-router-dom";
import { ScreenSizeProvider } from "./Services/ScreenSizeService/ScreenSizeService";
import { WalletService } from "./Services/WalletService/WalletService";
import { FirebaseProvider } from "./Services/FirebaseService/FirebaseService";
import { ProcessPurchaseProvider } from "./Services/ProcessPurchaseService/ProcessPurchaseService";

function App() {
  return (
    <div className="App">
      <FirebaseProvider>
        <ProcessPurchaseProvider>
          <WalletService>
            <ScreenSizeProvider>
              <BrowserRouter>
                <PreviewOverlay>
                  <MainLayout />
                </PreviewOverlay>
              </BrowserRouter>
            </ScreenSizeProvider>
          </WalletService>
        </ProcessPurchaseProvider>
      </FirebaseProvider>
    </div>
  );
}

export default App;
