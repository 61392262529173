import "./Footer.scss";
import discord from "../../assets/social/discord.svg";
import twitter from "../../assets/social/twitter.svg";

const Footer = () => {
  return (
    <footer>
      <div>
        Powered by{" "}
        <a href="https://anybodies.com/" target="_blank">
          Anybodies
        </a>
      </div>
      <div className="social-items-container">
        <a href="http://bit.ly/3EP3VKA" target="_blank" className="social-item">
          <img src={discord} alt="Toys R Us NFT Discord" />
        </a>
        <a href="http://bit.ly/3ERKVLL" target="_blank" className="social-item">
          <img src={twitter} alt="Toys R Us NFT Twitter" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
