import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";
import { createContext, useContext, useEffect, useState } from "react";
import * as axios from "axios";

export const BASE_API = `${window.location.href.includes('localhost')? 'https://toysrus.xyz/':window.location.href}api/presale/v1`

let app: firebase.app.App | undefined = firebase.initializeApp({
  apiKey: "AIzaSyABZVLQeN4r91nlyRHYww3Nr5yxblLRrfY",
  authDomain: "nft-anybodies.firebaseapp.com",
  projectId: "nft-anybodies",
  storageBucket: "nft-anybodies.appspot.com",
  messagingSenderId: "773321643955",
  appId: "1:773321643955:web:4d92166eafd8ab3958ddc6",
  measurementId: "G-QFKFEHZ0WL",
});

export const firestore = app.firestore();
firestore.settings({ experimentalForceLongPolling: true });
export const auth = app.auth();
export const storage = app.storage();
export const functions = app.functions();

type FirebaseFeatures = {
  firestore: firebase.firestore.Firestore;
  auth: firebase.auth.Auth;
  functions: firebase.functions.Functions;
  storage: firebase.storage.Storage;
  customFirebaseRequest: (functionName: string, data: any) => Promise<any>;
};

export const customFirebaseRequest = (functionName: string, data: any = {}) =>
  new Promise<any>((resolve) => {
    // axios.default
    //   .post<any>(
    //     `http://localhost:5000/nft-anybodies/us-central1/${functionName}`,
    //     {
    axios.default
      .post<any>(
        // `https://us-central1-nft-anybodies.cloudfunctions.net/${functionName}`,
        `${BASE_API}/${functionName}`,
        {
          // axios.default
          //   .post<any>(
          //     `https://stake.diamondvaults.io/api/v1/staking/${functionName}`,
          //     {
          data,
        }
      )
      .then((response) => {
        // handle success
        // console.log("response", response.data);
        resolve(response.data);
      })
      .catch((error: any) => {
        // handle error
        console.log(error);
        return resolve(undefined);
      });
  });

const FirebaseContext = createContext<FirebaseFeatures>({
  firestore,
  auth,
  functions,
  storage,
  customFirebaseRequest,
});

export function useFirebase() {
  return useContext(FirebaseContext);
}

export const FirebaseProvider: any = ({ children }: any) => {
  const value: FirebaseFeatures = {
    firestore,
    auth,
    functions,
    storage,
    customFirebaseRequest,
  };
  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};
