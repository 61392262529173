import { useEffect, useRef, useState } from "react";
import { usePreviewOverlay } from "../PreviewOverlay/PreviewOverlay";
import "./CarouselItem.scss";

export const CarouselItem = ({ geof, bg, name, mobile, isCurrent }: any) => {
  const [show, setShow] = useState(true);
  const { openPreview } = usePreviewOverlay();
  const containerRef = useRef<any>();

  useEffect(() => {
    if (mobile) {
      setShow(!isCurrent);
    }
  }, [mobile, isCurrent]);

  const change = () => {
    if (mobile) return;
    // openPreview({ LFG: "true" });

    containerRef.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
    setShow(!show);
  };

  return (
    <div
      ref={containerRef}
      className="carousel-item-main-container"
      onClick={change}
    >
      <div className="carousel-item-container">
        <div className="bg-container">
          <img className="bg-image" src={bg} alt="" />
        </div>
        <div className={show ? `geof-container-show` : "geof-container"}>
          <img
            className={show ? `geof-image-show` : "geof-image"}
            src={geof}
            alt=""
          />
        </div>
        <div className={show ? `info-container` : "info-container hide"}>
          <div className="title">{name}</div>
          {/* <div className="desc">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s,
          </div> */}
        </div>
      </div>
    </div>
  );
};
