import { Tooltip } from "antd";
import { useState } from "react";
import IconStar from "./IconStar";
import Star from "./Star";

const Marker = ({ text, positionStyles }: any) => {
  const [show, setShow] = useState(false);

  return (
    <div className="roadmap-marker-container" style={positionStyles}>
      <Tooltip
        title={text}
        trigger="click"
        placement="top"
        onOpenChange={(isOpen) => {
          setShow(isOpen);
        }}
      >
        <span className={`star ${show ? "pressed" : ""}`}>
          <Star />
          <IconStar starFill="none" starStroke="#FFF" />
        </span>
      </Tooltip>
    </div>
  );
};

export default Marker;
