import { useEffect, useState } from "react";
import "./navbar.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { Spin as Hamburger } from "hamburger-react";
import { useScreenSizeService } from "../../Services/ScreenSizeService/ScreenSizeService";
import icon from "../../assets/corp/icon.svg";
import logo from "../../assets/corp/logo.png";

const BREAK_POINT = 800;
const routes = [
  {
    text: "Home",
    route: "/",
  },
  {
    text: "BLUEPRINT",
    route: "/blueprint",
  },
];

const Navbar = () => {
  const { screenWidth } = useScreenSizeService();
  const [toggleMenu, setToggleMenu] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    document.body.style.overflowY = toggleMenu ? "hidden" : "auto";
  }, [toggleMenu]);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  return (
    <nav className="disable-selection">
      <div className="logos-container ">
        <img
          onClick={() => navigate("/")}
          src={logo}
          className="anybodies-brandname"
        />
      </div>

      {screenWidth > BREAK_POINT && (
        <>
          <ul className="list">
            {routes.map((route) => (
              <NavLink
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                key={route.text}
                to={route.route}
                className={({ isActive }) =>
                  !isActive ? "items" : "items-selected"
                }
              >
                {route.text}
              </NavLink>
            ))}
          </ul>
        </>
      )}
      {screenWidth <= BREAK_POINT && (
        <>
          {toggleMenu && (
            <div className="mobile-menu-container">
              <div onClick={toggleNav} className="menu-item-container">
                {toggleMenu &&
                  routes.map((route) => (
                    <NavLink
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                      key={route.text}
                      to={route.route}
                      className={({ isActive }) =>
                        !isActive ? "items" : "items-selected"
                      }
                    >
                      {route.text}
                    </NavLink>
                  ))}
              </div>
            </div>
          )}
        </>
      )}

      {screenWidth <= BREAK_POINT && (
        <div style={{ zIndex: 3, marginRight: "20px" }}>
          <Hamburger
            hideOutline={true}
            color="#0362af"
            toggled={toggleMenu}
            toggle={toggleNav}
          />
        </div>
      )}
      {/* <div onClick={toggleNav} style={{ "zIndex": 10 }} id="nav-icon4" className={toggleMenu ? 'open' : ''}>
                <span></span>
                <span></span>
                <span></span>
            </div> */}
      {/* <button onClick={toggleNav} className="menu-toggle-btn">BTN</button> */}
    </nav>
  );
};

export default Navbar;
