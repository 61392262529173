// import ComingSoon from "../Components/ComingSoon/ComingSoon";
// import Roadmap from "../Components/Roadmap";
import './RoadmapPage.scss'
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useEffect } from "react";
import Roadmap from "../Components/Roadmap2";
import roadmap from "../assets/roadmap.jpg";
import { useNavigate } from "react-router-dom";

const documentHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
};

const RoadmapPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    documentHeight();
    document.body.classList.add("roadmap-page");
    document.addEventListener("resize", documentHeight);
    return () => {
      document.body.classList.remove("roadmap-page");
      document.removeEventListener("resize", documentHeight);
    };
  }, []);

  // return <ComingSoon />
  return (
    <>
        <div className='blueprint-disclaimer'>Blueprint for entertainment and illustrative purposes only. Subject to change without notice.</div>

      <div className="roadmap-header">
        <Button
          icon={<ArrowLeftOutlined color="#FFF" />}
          shape="circle"
          color="#FFF"
          className="back-button"
          onClick={() => {
            navigate("/");
          }}
        />
        <Button
          type="dashed"
          className="get-image"
          onClick={() => {
            window.open(roadmap, "_blank");
          }}
        >
          Get full image
        </Button>

      </div>
      <Roadmap />
    </>
  );
};

export default RoadmapPage;
