import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { MagicEden, MintRaffle } from "./MintRaffle/MintRaffle";
import "./MintRaffleContainer.scss";

export const MintRaffleContainer = () => {
  return (
    <div className="mint-raffle-container">
      <div className="presale-title">PRESALE</div>
      <div style={{ marginBottom: "50px" }}>
        <WalletMultiButton className="wallet-btn" />
      </div>
      {/* <MintRaffle
        showSubmission={false}
        showDisclaimer={false}
        showPricing={false}
        premintId={"v4ecGalFHNF2W7uQsX75"}
      /> */}
      <MintRaffle
        showSubmission={false}
        showDisclaimer={false}
        showPricing={false}
        premintId={"skq2DzsSRSeHxbUneLjI"}
      />
      <MintRaffle
        showSubmission={false}
        showPricing={false}
        showDisclaimer={true}
        premintId={"nQ9nxdbyZIsGmGjVbiJ2"}
      />
      <MintRaffle
        showSubmission={false}
        showDisclaimer={true}
        showPricing={true}
        premintId={"WvvEqkKO05SAUjFWOUMd"}
      />
    </div>
  );
};
