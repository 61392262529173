import { Navigate, Route, Routes } from "react-router-dom";
import Footer from "../Components/Footer/Footer";
import { MainPage } from "../Pages/MainPage";
import RoadmapPage from "../Pages/RoadmapPage";
import "./MainLayout.scss";
import Navbar from "./navbar/navbar";

export const MainLayout = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<MainPage />}></Route>
        <Route path="/BLUEPRINT" element={<RoadmapPage />}></Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </>
  );
};
