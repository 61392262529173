import "./styles.scss";
import roadmap from "../../assets/roadmap.jpg";
import Marker from "./Marker";
import MobileCarousel from "./MobileCarousel";
import { useState } from "react";
import { roadmapMarkers } from "./markers";

const Roadmap = () => {
  const [slide, setSlide] = useState(0);

  return (
    <div className="roadmap-presentation" data-slide={slide}>
      <div className="roadmap-presentation-image">
        <img
          src={roadmap}
          alt="Roadmap"
          height="100%"
          style={{
            height: roadmapMarkers[slide].mobile.imageHeight + "vh",
            transform: `translate(${roadmapMarkers[slide].mobile.position.left}vh, ${roadmapMarkers[slide].mobile.position.top}vh)`,
          }}
        />
        {roadmapMarkers.map(({ description, top, left, rotate }, index) => (
          <Marker
            key={index}
            text={description}
            positionStyles={{
              top: `${top}%`,
              left: `${left}%`,
              transform: `rotate(${rotate}deg)`,
            }}
          />
        ))}
      </div>
      <MobileCarousel setSlide={setSlide} />
    </div>
  );
};

export default Roadmap;
