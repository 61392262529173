import Progress from "antd/lib/progress";
import "./Progress.scss";

interface Props {
  showBar: boolean;
  isLive: boolean;
  currencyName: string;
  bought: number;
  total: number;
  price: number;
  priceType: string;
}

const ProgressComponent = ({
  priceType,
  showBar,
  currencyName,
  bought,
  total,
  price,
  isLive,
}: Props) => {
  return (
    <>
      {showBar && (
        <>
          <div className="stats-container">
            <div className="percent-container">
              <div>{bought}</div>
              <div>/{total}</div>
              <div>&nbsp;&nbsp;PURCHASED</div>
            </div>
          </div>
          <Progress
            percent={(bought / total) * 100}
            trailColor="#6e817f"
            strokeWidth={16}
            status="normal"
            showInfo={false}
          />
        </>
      )}
      {isLive && priceType === "flat" && (
        <div
          style={{ textAlign: "center", color: "#0362af", fontSize: "1rem" }}
        >
          {price} ${currencyName} / ITEM
        </div>
      )}
    </>
  );
};

export default ProgressComponent;
