import { PublicKey } from "@solana/web3.js";
import { useEffect, useState } from "react";
import { useFirebase } from "../Services/FirebaseService/FirebaseService";

interface Props {
  presaleId: string;
  walletAddress: PublicKey;
}

const useRaffleTickets = ({
  presaleId,
  walletAddress,
}: Props): [boolean, number, number] => {
  const { firestore } = useFirebase();

  const [busy, setBusy] = useState(true);
  const [wlSuccessTickets, setWlSuccessTickets] = useState(0);
  const [wlProccessTickets, setWlProccessTickets] = useState(0);

  const setState = (success?: number, process?: number) => {
    setBusy(typeof success === "undefined");
    setWlSuccessTickets(success || 0);
    setWlProccessTickets(process || 0);
  };

  useEffect(() => {
    setState();
    if (walletAddress) {
      const unsubscribe = firestore
        .collection(`/AMintRaffle/${presaleId}/ABuyMintTicketRequest`)
        .where("walletAddress", "==", walletAddress.toString())
        .where("status", "in", ["pending", "process", "success"])
        .onSnapshot(({ docs }) => {
          let successSum = 0,
            processSum = 0;
          for (let doc of docs) {
            const { status, numTickets } = doc.data();
            if (status === "success") {
              successSum += numTickets;
            } else {
              processSum += numTickets;
            }
          }
          setState(successSum, processSum);
        });
      return unsubscribe;
    }
  }, [walletAddress]);

  return [busy, wlSuccessTickets, wlProccessTickets];
};

export default useRaffleTickets;
