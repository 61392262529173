import { CarouselPreview } from "../Components/CarouselPreview/CarouselPreview";
import { MintRaffleContainer } from "../Components/MintRaffleContainer/MintRaffleContainer";

export const MainPage = () => {
  return (
    <>
      <CarouselPreview />
      <MintRaffleContainer />
    </>
  );
};
