export const roadmapMarkers = [
  {
    description:
      "10,000 digital collectibles launching on Solana, based on Geoffrey the Giraffe.",
    top: 19.25,
    left: 29.75,
    rotate: 9,
    mobile: {
      imageHeight: 200,
      position: {
        left: 75,
        top: 30,
      },
    },
  },
  {
    description:
      "$GCoins, an SPL token earned by staking your digital collectibles, attending events, shopping online & in-store, playing with toys and more.",
    top: 45.5,
    left: 34.5,
    rotate: 9,
    mobile: {
      imageHeight: 240,
      position: {
        left: 67,
        top: -20,
      },
    },
  },
  {
    description:
      "Unlock unique experiences that include VIP access to new Toys”R”Us flagship stores, exclusive shopping events, and more one-of-a-kind experiences that you can only get at ToysRUs!",
    top: 67.5,
    left: 26.75,
    rotate: 9,
    mobile: {
      imageHeight: 160,
      position: {
        left: 71,
        top: -40,
      },
    },
  },
  {
    description:
      "Creating next-gen toy ownership with Anybodies using NFT, NFC and AR technology to bring toys into your favorite games, social media etc.",
    top: 22,
    left: 45,
    rotate: 9,
    mobile: {
      imageHeight: 160,
      position: {
        left: 5,
        top: 30,
      },
    },
  },
  {
    description:
      "A campaign to collaborate with your favorite NFT projects, bringing their collections to life as limited-edition physical collectible toys.",
    top: 30.5,
    left: 55,
    rotate: 9,
    mobile: {
      imageHeight: 180,
      position: {
        left: -40,
        top: 5,
      },
    },
  },
  {
    description:
      "Digital Flagship Toys”R”Us stores built in all your favorite games.\nExperience and buy toys like never before!",
    top: 55.5,
    left: 49.5,
    rotate: 9,
    mobile: {
      imageHeight: 150,
      position: {
        left: 0,
        top: -20,
      },
    },
  },
  {
    description:
      "A marketplace to buy, sell and trade your physical & digital toys, including unique perks such as early access to hot toys and more!",
    top: 45.25,
    left: 65.75,
    rotate: 9,
    mobile: {
      imageHeight: 120,
      position: {
        left: -64,
        top: 0,
      },
    },
  },

  {
    description: "REDACTED 👀",
    top: 9.5,
    left: 21,
    rotate: 9,
    mobile: {
      imageHeight: 230,
      position: {
        left: 125,
        top: 60,
      },
    },
  },
];
