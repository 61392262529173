import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CarouselItem } from "../CarouselItem/CarouselItem";
import "./CarouselPreview.scss";
import geof1 from "./images/geof/geof1.png";
import geof2 from "./images/geof/geof2.png";
import geof3 from "./images/geof/geof3.png";
import geof4 from "./images/geof/geof4.png";
import geof5 from "./images/geof/geof5.png";
import geof6 from "./images/geof/geof6.png";
import geof8 from "./images/geof/geof8.png";
import geof9 from "./images/geof/geof9.png";
import geof10 from "./images/geof/geof10.png";
import geof11 from "./images/geof/geof11.png";
import geof12 from "./images/geof/geof12.png";
import geof13 from "./images/geof/geof13.png";
import bg1 from "./images/bg/bg1.png";
import bg2 from "./images/bg/bg2.png";
import bg3 from "./images/bg/bg3.png";
import bg4 from "./images/bg/bg4.png";
import bg5 from "./images/bg/bg5.png";
import bg6 from "./images/bg/bg6.png";
import bg7 from "./images/bg/bg7.png";
import bg8 from "./images/bg/bg8.png";
import bg9 from "./images/bg/bg9.png";
import bg10 from "./images/bg/bg10.png";
import bg12 from "./images/bg/bg12.png";
import bg13 from "./images/bg/bg13.png";
import bg14 from "./images/bg/bg14.png";
import bg15 from "./images/bg/bg15.png";
import { useRef, useState } from "react";

const cards = [
  { geof: geof8, bg: bg7, name: "astronaut" },
  { geof: geof9, bg: bg15, name: "Trooper" },
  { geof: geof3, bg: bg5, name: "gamer" },
  { geof: geof5, bg: bg14, name: "Captain" },
  { geof: geof2, bg: bg4, name: "city boy" },
  { geof: geof6, bg: bg15, name: "fire fighter" },
  { geof: geof1, bg: bg1, name: "superhero" },
  // { geof: geof13, bg: bg9, name: "Fisherman" },
  { geof: geof10, bg: bg12, name: "Head Chef" },
  { geof: geof11, bg: bg6, name: "scientist" },
  { geof: geof12, bg: bg3, name: "Officer" },
  { geof: geof4, bg: bg10, name: "Soldier" },
];

const responsive = {
  mobile: {
    breakpoint: { max: 601, min: 0 },
    items: 1,
    partialVisibilityGutter: 60,
  },
};

export const CarouselPreview = () => {
  const [slide, setSlide] = useState(0);
  return (
    <div className="preview-main-container">
      <Carousel
        swipeable
        draggable
        responsive={responsive}
        className="pb-10 carousel"
        transitionDuration={500}
        containerClass="carousel-container disable-selection carousel-view-mobile"
        deviceType="mobile"
        additionalTransfrom={0}
        afterChange={(previousSlide, { currentSlide }) => {
          setSlide(currentSlide);
        }}
      >
        {cards.map(({ geof, bg, name }, index) => (
          <CarouselItem
            geof={geof}
            bg={bg}
            name={name}
            mobile
            isCurrent={slide === index}
            key={name}
          />
        ))}
      </Carousel>
      <div className="preview-asd disable-selection">
        {cards.map(({ geof, bg, name }) => (
          <CarouselItem geof={geof} bg={bg} name={name} key={name} />
        ))}
      </div>
    </div>
  );
};
