import { useWallet } from "@solana/wallet-adapter-react";
import { Button, InputNumber, Spin, Statistic } from "antd";
import { useEffect, useState } from "react";
import {
  customFirebaseRequest,
  useFirebase,
} from "../../../Services/FirebaseService/FirebaseService";
import "./MintRaffle.scss";
import { message } from "antd";
import { sendLocalTransactions } from "../../../Services/Web3/TransactionBuilder";
import { Transaction } from "@solana/web3.js";
import { checkWalletBalance } from "../../../Services/Web3/BalanceChecker";
import { buyRaffleTickets } from "../../../API/Raffles/BuyRaffleTicket";
import { useProcessPurchase } from "../../../Services/ProcessPurchaseService/ProcessPurchaseService";
import moment from "moment";
import Countdown from "react-countdown";
import MintBlock from "./MintBlock/MintBlock";
import Progress from "./Progress";
import me from "../../../assets/corp/me.png";

enum MintState {
  Waiting = "waiting",
  Live = "live",
  Completed = "completed",
}

interface Props {
  premintId: string;
  showPricing: boolean;
  showSubmission: boolean;
  showDisclaimer: boolean;
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const MintRaffle = ({
  premintId,
  showPricing,
  showSubmission,
  showDisclaimer
}: Props) => {
  const { firestore } = useFirebase();
  const { publicKey, signTransaction } = useWallet();
  const [busyBuying, setBusyBuying] = useState(false);
  const [presaleDoc, setPresaleDoc] = useState<any>(null);
  const { addPurchaseListener } = useProcessPurchase();
  const [total, setTotal] = useState(0);
  const [availableTickets, setAvailableTickets] = useState(0);
  const [loadingIsWl, setLoadingIsWl] = useState(false);
  const [isWl, setIsWl] = useState(false);
  const [wlTicketsTotal, setWlTicketsTotal] = useState(0);
  const [price, setPrice] = useState(0);
  const [raffleStart, setRaffleStart] = useState<any>(null);
  const [raffleEnd, setRaffleEnd] = useState<any>(null);
  const [priceType, setPriceType] = useState<any>(null);
  const [priceOptions, setPriceOptions] = useState<any>(null);
  const [currencyName, setCurrencyName] = useState("");
  const [mintState, setMintState] = useState<MintState | null>(null);
  const [presaleTitle, setPresaleTitle] = useState("");
  const [needShowProgress, setShowProgress] = useState(false);

  useEffect(() => {
    return firestore.doc(`/AMintRaffle/${premintId}`).onSnapshot((doc) => {
      setPresaleDoc(doc);
      const data = doc.data();
      if (typeof data !== "undefined") {
        const {
          numOfTickets,
          purchasedTickets,
          lockedTickets,
          ticketPrice,
          token: { name },
          startDate,
          endDate,
          title,
          priceType,
          priceOptions,
          showProgress,
        } = data;

        checkMintStateByDates(startDate, endDate);

        setShowProgress(showProgress);
        setPresaleTitle(title);
        setPriceOptions(priceOptions);
        setPrice(ticketPrice);
        setPriceType(priceType);
        setCurrencyName(name);
        setRaffleStart(startDate);
        setRaffleEnd(endDate);
        setTotal(numOfTickets);
        setAvailableTickets(numOfTickets - purchasedTickets - lockedTickets);
      }
    });
  }, []);

  const checkMintStateByDates = async (
    startDate: any,
    endDate: any,
    needWait: boolean = false
  ) => {
    if (needWait) {
      await sleep(1000);
    }
    if (moment().isBefore(startDate.toDate())) {
      setMintState(MintState.Waiting);
    } else if (
      moment().isBetween(moment(startDate.toDate()), moment(endDate.toDate()))
    ) {
      setMintState(MintState.Live);
    } else {
      setMintState(MintState.Completed);
    }
  };

  useEffect(() => {
    if (!publicKey) {
      setIsWl(false);
      setLoadingIsWl(false);
      setWlTicketsTotal(0);
      return;
    }
    getWL();
  }, [publicKey]);

  const getWL = async () => {
    setLoadingIsWl(true);
    try {
      const response = await customFirebaseRequest("getWhitelistStatus", {
        presaleId: premintId,
        walletAddress: publicKey,
      });

      setIsWl(response?.isWhitelisted);
      if (response?.isWhitelisted) {
        setWlTicketsTotal(response.count);
      }
    } catch (error) {
      console.error(error);
    }
    setLoadingIsWl(false);
  };

  const handlePurchase = async (numTickets: number) => {
    if (publicKey && signTransaction && presaleDoc) {
      const {
        numOfTickets,
        token,
        ticketPrice,
        purchasedTickets,
        configWalletAddress,
      } = presaleDoc.data();

      const { isSol } = token;

      setBusyBuying(true);
      const hide = message.loading("Busy creating your transaction... ", 0);

      let hasEnough = false;
      const SOLTransfers: {
        fromAddress: string;
        toAddress: string;
        solAmount: number;
      }[] = [];
      const SPLTransfers: {
        fromAddress: string;
        toAddress: string;
        tokenAddress: string;
        amount: number;
      }[] = [];
      console.log(isSol);
      const currentTicketPrice =
        priceType === "options"
          ? priceOptions.find(({ amount }: any) => amount === numTickets)
              .priceSol
          : ticketPrice;

      // Payment
      if (isSol === true) {
        const totalSolPrice = currentTicketPrice * numTickets;
        SOLTransfers.push({
          fromAddress: publicKey.toString(),
          toAddress: configWalletAddress,
          solAmount: totalSolPrice,
        });
        hasEnough = await checkWalletBalance({
          walletAddress: publicKey.toString(),
          solAmount: totalSolPrice,
        });
      } else {
        const decimal = token.decimalPoints;
        const price = currentTicketPrice * decimal * numTickets;
        console.log({ price });

        SPLTransfers.push({
          fromAddress: publicKey.toString(),
          toAddress: configWalletAddress,
          amount: price,
          tokenAddress: token.address,
        });
        hasEnough = await checkWalletBalance({
          walletAddress: publicKey.toString(),
          tokens: [
            {
              name: token.name,
              address: token.address,
              amount: currentTicketPrice * numTickets,
            },
          ],
        });
      }

      if (hasEnough) {
        try {
          const transaction = (await sendLocalTransactions(
            SOLTransfers,
            SPLTransfers,
            publicKey.toString(),
            signTransaction,
            (update, message) => {
              console.log(update, message);
            },
            true
          )) as Transaction;

          const data = await buyRaffleTickets(presaleDoc.id, {
            trans: transaction.serialize(),
            walletAddress: publicKey.toString(),
            numTickets,
          });

          if (data) {
            hide();
            await addPurchaseListener!(data);
          } else {
            hide();
          }
        } catch (err) {
          console.error(err);
          hide();
        }
      } else {
        hide();
      }
    }
    setBusyBuying(false);
  };

  const isSoldOut = () => availableTickets === 0;

  return (
    <div className="mint-raffle-main-container">
      {!presaleDoc && (
        <div className="spinner-container">
          <Spin />
        </div>
      )}
      {mintState === MintState.Live && (
        <>
          {!presaleDoc && (
            <div className="spinner-container">
              <Spin />
            </div>
          )}

          <div className="raflle-info-container">
            <div className="stage-container">
              <div className="title">{presaleTitle}</div>
              <div className="label">Stage</div>
            </div>
            <div>
              {/* <div className="ends-in">ENDS IN</div> */}
              <Countdown
                date={raffleEnd.toDate()}
                renderer={rendererEnding}
                onComplete={() => {
                  checkMintStateByDates(raffleStart, raffleEnd, true);
                }}
              />
            </div>
          </div>
          <SubmissionRedirect showSubmission={showSubmission} />
          {!loadingIsWl && publicKey ? (
            isWl ? (
              <MintBlock
                priceType={priceType}
                priceOptions={priceOptions}
                itemPrice={price}
                tokenName={presaleDoc.data().token.name}
                busyBuying={busyBuying}
                publicKey={publicKey}
                onPressMint={handlePurchase}
                premintId={premintId}
                live={mintState === MintState.Live}
                isSoldOut={isSoldOut}
                wlTicketsTotal={wlTicketsTotal}
              />
            ) : (
              <div className="no-wl">Your wallet isn't listed for presale</div>
            )
          ) : (
            <div className="mint-block-not-connect-wallet">
              {loadingIsWl ? <Spin /> : "Connect your wallet"}
            </div>
          )}
          {isSoldOut() && <div className="sold-out">SOLD OUT :)</div>}

          <Progress
            showBar={needShowProgress}
            bought={total - availableTickets}
            total={total}
            isLive={mintState === MintState.Live}
            price={price}
            currencyName={currencyName}
            priceType={priceType}
          />
        </>
      )}
      {mintState === MintState.Waiting && (
        <>
          <div className="pre-title">{presaleTitle}</div>
          {showPricing && (
            <div className="pre-price">
              <div className="raflle-info-container center-items">
                <div className="stage-container">
                  <div className="title">{total.toLocaleString()}</div>
                  <div className="label">Supply</div>
                </div>
                <div className="stage-container">
                  <div className="title">
                    {`${price.toLocaleString()} $${currencyName}`}
                  </div>
                  <div className="label">Price</div>
                </div>
              </div>
            </div>
          )}
          <SubmissionRedirect showSubmission={showSubmission} />

          <div className="opens-in">OPENING SOON</div>
          <Countdown
            date={raffleStart.toDate()}
            renderer={renderer}
            onComplete={() => {
              checkMintStateByDates(raffleStart, raffleEnd, true);
            }}
          />
        </>
      )}
      {mintState === MintState.Completed && (
        <>
          {!presaleDoc && (
            <div className="spinner-container">
              <Spin />
            </div>
          )}

          <div className="raflle-info-container">
            <div className="stage-container">
              <div className="title">{presaleTitle}</div>
              <div className="label">Stage</div>
            </div>
            <div>
              <div className="ends-in">ENDED</div>
            </div>
          </div>
          {!loadingIsWl && publicKey ? (
            isWl ? (
              <MintBlock
                priceType={priceType}
                priceOptions={priceOptions}
                itemPrice={price}
                tokenName={presaleDoc.data().token.name}
                busyBuying={busyBuying}
                publicKey={publicKey}
                onPressMint={handlePurchase}
                premintId={premintId}
                live={false}
                isSoldOut={isSoldOut}
                wlTicketsTotal={wlTicketsTotal}
              />
            ) : (
              <div className="no-wl">Your wallet isn't listed for presale</div>
            )
          ) : (
            <div className="mint-block-not-connect-wallet">
              {loadingIsWl ? <Spin /> : "Connect your wallet"}
            </div>
          )}
          {isSoldOut() && <div className="sold-out">SOLD OUT :)</div>}

          <Progress
            showBar={needShowProgress}
            bought={total - availableTickets}
            total={total}
            price={price}
            isLive={false}
            currencyName={currencyName}
            priceType={priceType}
          />
        </>
      )}
      {mintState === MintState.Live && showDisclaimer && (
        <div className="disclaimer">{`TRU NFTs will be airdropped at the end of the mint and will be locked for a 30 day vesting period`}</div>
      )}

      <MagicEden />
    </div>
  );
};

// Renderer callback with condition
const rendererEnding = ({ days, hours, minutes, seconds, completed }: any) => {
  // Render a countdown
  return (
    <div className="time-left-end">
      {/* <div className="items-container">
        <div className="item-container">
          <div className="item-value">{days}</div>
          <div className="item-title">D</div>
        </div>
        <div className="countdown-divider"></div>
        <div className="item-container">
          <div className="item-value">{hours}</div>
          <div className="item-title">H</div>
        </div>
        <div className="countdown-divider"></div>
        <div className="item-container">
          <div className="item-value">{minutes}</div>
          <div className="item-title">M</div>
        </div>
        <div className="countdown-divider"></div>
        <div className="item-container">
          <div className="item-value">{seconds}</div>
          <div className="item-title">S</div>
        </div>
      </div> */}
    </div>
  );
};

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
  // Render a countdown
  return (
    <div className="time-left">
      {/* <div className="items-container">
        <div className="item-container">
          <div className="item-value">{days}</div>
          <div className="item-title">D</div>
        </div>
        <div className="countdown-divider"></div>
        <div className="item-container">
          <div className="item-value">{hours}</div>
          <div className="item-title">H</div>
        </div>
        <div className="countdown-divider"></div>
        <div className="item-container">
          <div className="item-value">{minutes}</div>
          <div className="item-title">M</div>
        </div>
        <div className="countdown-divider"></div>
        <div className="item-container">
          <div className="item-value">{seconds}</div>
          <div className="item-title">S</div>
        </div>
      </div> */}
    </div>
  );
};

export const MagicEden = () => {
  const openMintPage = () =>
    window.open("https://bit.ly/3gzdPHa", "_blank")?.focus();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <div
        style={{
          color: "#0362af",
          fontSize: "10px",
          textAlign: "center",
        }}
      >
        LAUNCHING ON
      </div>
      <img
        onClick={openMintPage}
        style={{ width: "130px", cursor: "pointer" }}
        src={me}
        alt=""
      />
    </div>
  );
};

const SubmissionRedirect = ({ showSubmission }: any) => {
  return (
    <>
      {showSubmission && (
        <div className="presale-link-container">
          <Button
            className="presale-button"
            href="https://bit.ly/3VpQwhO"
            target="_blank"
          >
            GET PRESALE SPOTS NOW!
          </Button>
        </div>
      )}
    </>
  );
};
