const IconStar = ({ width, height, starFill, starStroke, className }: any) => {
  return (
    <svg
      className="icon-star"
      width={width}
      height={height}
      viewBox="0 0 44 57"
    >
      <g className="icon-star-group" fillRule="evenodd">
        <path
          className="icon-star-path"
          fill={starFill}
          stroke={starStroke}
          strokeWidth="2"
          d="M22.5 33.983l-5.87 3.087 1.12-6.538-4.75-4.63 6.564-.954L22.5 19l2.936 5.948 6.564.954-4.75 4.63 1.12 6.538z"
        />
        <g className="icon-star-celebrate">
          <path
            className="icon-star-stripe-top-left"
            fill="none"
            stroke="#FFF"
            strokeWidth="2"
            d="M4.78 12.117s-1.183 2.375-.268 3.11c.915.736 4.068-.067 4.538.818.47.885-2.167 2.985-1.047 3.612 1.12.628 3.798-2.543 4.926-1.57 1.127.97.27 3.958.27 3.958"
          />
          <path
            className="icon-star-stripe-bottom-left"
            fill="none"
            stroke="#FFF"
            strokeWidth="2"
            d="M10.07 33.95s1.188 2.237.33 2.942c-.86.706-3.896-.026-4.325.817-.43.84 2.146 2.806 1.09 3.41-1.057.605-3.698-2.373-4.755-1.442-1.056.93-.165 3.752-.165 3.752"
          />
          <path
            className="icon-star-stripe-bottom-center"
            fill="none"
            stroke="#FFF"
            strokeWidth="2"
            d="M23.07 39.592s-1.45 2.016-1.24 3.172c.207 1.155 3.113 1.617 2.888 2.594-.226.976-2.594 1.83-2.16 3.04.432 1.207 3.534.57 3.748 2.045.214 1.473-2.392 3.166-2.392 3.166"
          />
          <path
            className="icon-star-stripe-bottom-right"
            fill="none"
            stroke="#FFF"
            strokeWidth="2"
            d="M31.88 37.604s2.023-1.524 2.854-.786c.83.737.584 3.852 1.483 4.144.9.292 2.437-2.56 3.2-1.61.762.95-1.767 4.023-.682 4.92 1.085.9 3.732-.422 3.732-.422"
          />
          <path
            className="icon-star-stripe-top-right"
            fill="none"
            stroke="#FFF"
            strokeWidth="2"
            d="M29.555 22.057s-1.182-2.375-.267-3.11c.915-.736 4.067.067 4.538-.818.47-.886-1.167-1.986-.048-2.613 1.12-.628 2.8.543 3.926-.43 1.128-.97 1.272-1.958 1.272-1.958"
          />
          <path
            className="icon-star-stripe-top-center"
            fill="none"
            stroke="#FFF"
            strokeWidth="2"
            d="M21.718 1.084s-1.777 1.734-1.773 2.908c.004 1.174 2.785 2.134 2.394 3.056-.393.922-2.874 1.353-2.657 2.618.217 1.264 3.383 1.178 3.337 2.666-.045 1.488-2.906 2.702-2.906 2.702"
          />
          <g
            className="icon-star-dots"
            fill="#B0B4BA"
            transform="translate(6 28)"
          >
            <circle className="icon-star-dot" cx="1" cy="3" r="1" />
            <circle className="icon-star-dot" cx="11" cy="17" r="1" />
            <circle className="icon-star-dot" cx="8" cy="19" r="1" />
            <circle className="icon-star-dot" cx="28" cy="4" r="1" />
            <circle className="icon-star-dot" cx="33" cy="1" r="1" />
            <circle className="icon-star-dot" cx="30" cy="1" r="1" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconStar;
