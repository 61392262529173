import { PublicKey } from "@solana/web3.js";
import Spin from "antd/lib/spin";
import { useEffect, useState } from "react";
import useRaffleTickets from "../../../../hooks/useRaffleTickets";
import "./MintBlock.scss";
import { PriceSelect } from "./PriceSelect/PriceSelect";

interface Props {
  itemPrice: number;
  priceType: string;
  priceOptions?: { priceSol: number; amount: number }[];
  busyBuying: boolean;
  publicKey: PublicKey;
  onPressMint: (count: number) => void;
  premintId: string;
  tokenName: string;
  wlTicketsTotal: number;
  isSoldOut: () => boolean;
  live: boolean;
}

const MintBlock = ({
  itemPrice,
  busyBuying,
  publicKey,
  onPressMint,
  premintId,
  tokenName,
  wlTicketsTotal,
  isSoldOut,
  priceOptions,
  priceType,
  live,
}: Props) => {
  const [mintCount, setMintCount] = useState(0);
  const [canPurchaseCount, setCanPurchaseCount] = useState(0);
  const [fullPrice, setFullPrice] = useState(0);
  const [priceSelectValue, setPriceSelectValue] = useState(0);
  const [busy, wlSuccessTickets, wlProccessTickets] = useRaffleTickets({
    presaleId: premintId,
    walletAddress: publicKey,
  });

  useEffect(() => {
    if (
      (!busy ||
        (mintCount && mintCount >= 1 && mintCount <= canPurchaseCount)) &&
      priceType === "flat"
    ) {
      setFullPrice(+(itemPrice * mintCount).toFixed(2));
    }
  }, [mintCount]);

  useEffect(() => {
    if (wlTicketsTotal) {
      const max = wlTicketsTotal - wlSuccessTickets - wlProccessTickets;
      setCanPurchaseCount(max);
      if (mintCount > max) {
        setMintCount(max);
      }
    }
  }, [wlTicketsTotal, wlSuccessTickets, wlProccessTickets]);

  const buttonPress = (isDecrease?: boolean) => {
    if (isDecrease) {
      setMintCount((state) => state - 1);
    } else {
      setMintCount((state) => state + 1);
    }
  };

  const checkInputValue = () => {
    if (!mintCount) {
      setMintCount(1);
    }
    if (mintCount > canPurchaseCount) {
      setMintCount(canPurchaseCount);
    }
  };

  const handlePressMint = () => {
    if (mintCount > canPurchaseCount) {
      return;
    }
    onPressMint(mintCount);
  };

  const selectChange = (index: string) => {
    const information = priceOptions?.[+index] as any;
    const total = information?.amount * information?.priceSol;
    setFullPrice(+total.toFixed(2));
    setMintCount(information?.amount);
  };

  return (
    <div className="mint-block">
      {!isSoldOut() && live && priceType === "flat" && (
        <div className="counter">
          <button
            type="button"
            disabled={busy || busyBuying || !mintCount || mintCount <= 1}
            onClick={() => buttonPress(true)}
          >
            -
          </button>
          <input
            type="number"
            value={mintCount}
            onChange={(e) => setMintCount(parseInt(e.currentTarget.value))}
            onBlur={checkInputValue}
            disabled={busy || busyBuying}
          />
          <button
            type="button"
            disabled={
              busy || busyBuying || !mintCount || mintCount >= canPurchaseCount
            }
            onClick={() => buttonPress()}
          >
            +
          </button>
        </div>
      )}
      {!isSoldOut() && live && priceType === "options" && priceOptions && (
        <div className="select-price-container">
          <PriceSelect
            available={canPurchaseCount}
            priceOptions={priceOptions}
            busyBuying={busyBuying}
            handleChange={selectChange}
          />
        </div>
      )}
      Available presale spots for wallet : {wlTicketsTotal}
      <div>
        {/* Purchased in progress: {wlProccessTickets}
        <br /> */}
        <div className="purchased-items">
          Purchased successfully: {wlSuccessTickets}
        </div>
      </div>
      {live && (
        <>
          {busyBuying ? (
            <div className="buttonLoader">
              <Spin />
            </div>
          ) : (
            <>
              {!isSoldOut() && (
                <button
                  type="button"
                  className="mint-button"
                  onClick={handlePressMint}
                  disabled={
                    busy ||
                    busyBuying ||
                    !mintCount ||
                    mintCount < 1 ||
                    mintCount > canPurchaseCount
                  }
                >
                  Purchase
                  {!!fullPrice && (
                    <>
                      <br />
                      <span className="total">
                        Total: {fullPrice} ${tokenName}
                      </span>
                    </>
                  )}
                </button>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MintBlock;
