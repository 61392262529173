import Carousel from "react-multi-carousel";
import { roadmapMarkers } from "./markers";

const MobileCarousel = ({ setSlide }: any) => {
  return (
    <div className="roadmap-mobile-carousel">
      <Carousel
        showDots
        swipeable
        draggable
        transitionDuration={500}
        responsive={{
          mobile: {
            breakpoint: { max: 1001, min: 0 },
            items: 1,
            partialVisibilityGutter: 60,
          },
        }}
        additionalTransfrom={0}
        afterChange={(previousSlide, { currentSlide }) => {
          setSlide(currentSlide);
        }}
        arrows={false}
      >
        {roadmapMarkers.map(({ description }, index) => (
          <div className="roadmap-mobile-carousel-item" key={index}>
            <div className="description">{description}</div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default MobileCarousel;
