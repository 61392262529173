import { Select } from "antd";
import "./PriceSelect.scss";
interface Props {
  available: number;
  priceOptions: { priceSol: number; amount: number }[];
  busyBuying: boolean;
  handleChange: (index: string) => void;
}

export const PriceSelect = ({
  available,
  busyBuying,
  priceOptions,
  handleChange,
}: Props) => {
  return (
    <div className="price-selector">
      <Select
        disabled={busyBuying}
        placeholder={"Select a pack"}
        style={{ width: 250 }}
        onChange={handleChange}
        options={priceOptions.map(({ priceSol, amount }, index) => ({
          value: index,
          label: (
            <div className="price-option-container">
              <div className="title">{`${amount} NFTS`}</div>
              <div>{`${priceSol} $SOL / NFT`}</div>
            </div>
          ),
          disabled: amount > available,
        }))}
      />
    </div>
  );
};
